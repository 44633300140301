export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'black',
      // borderBottom: '2px solid',
      // borderColor: 'secondary',
      padding: ['0.5rem', '0.5rem', '1rem', '0.25rem 0.5rem'],
      zIndex: '1001'
    },

    '.containerScrolled': {
      backgroundColor: 'black',
      borderBottom: '1px solid',
      borderColor: 'secondary',
      zIndex: '1001'
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        padding: '1rem',
        a: {
          color: 'white',
          fontFamily: 'body',
          fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem'],
          fontWeight: '500'
        }
      }
    },

    '.navItem': {
      margin: '0rem',
      padding: ['1.5rem 1rem', '1.5rem 1rem', '1.5rem 1rem'],
      a: {
        color: 'text'
      },
      ':hover': {
        opacity: '0.8'
      }
    },

    '.navMenuLogo': {
      position: 'static',
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem 0rem 2rem',
      img: {
        maxHeight: ['55px', '', '75px', '']
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'background',
      width: ['85%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      alignItems: ['flex-start'],
      padding: ['1rem', '2rem'],
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem'
      },
      '.navItem': {
        padding: ['0.75rem', '0.75rem', '1rem', '0.75rem', '0.75rem', '1rem'],
        a: {
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem']
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      width: '100%',
      maxWidth: 'unset',
      padding: '1rem 0rem'
    },
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {
        fontSize: ['1.1rem', '1.5rem'],
        textAlign: 'left'
      },
      a: {
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      },
      margin: '0rem',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      color: 'text',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'text'
        }
      }
    },

    '.hamburger > div': {
      backgroundColor: 'light'
    },
    '.hamburgerOpen > div': {
      backgroundColor: 'text'
    },

    '.logoLocationContainer': {
      padding: '0rem'
    },
    '.logo': {
      padding: '0rem',
      img: {
        opacity: '1',
        maxHeight: ['55px', '100px'],
        filter: 'unset'
      }
    },
    '.logoScrolled': {
      img: {
        opacity: '1',
        maxHeight: ['55px', '70px']
      }
    },
    '.hamburger': {
      display: ['', '', '', 'none']
    }
  },

  footer: {
    borderTop: 'solid 2px',
    borderColor: 'text',
    '.image': {},
    '.multiButtonContainer': {
      a: {}
    },
    '.contactDetails-container': {},
    '.copyright': {},
    '.socialIconsContainer': {
      a: {}
    },
    '.gonationLogo': {},
    '.poweredByText': {
      justifyContent: 'flex-start'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    // variant: 'customVariants.gradientText',
    fontSize: ['1.4rem', '1.5rem', '1.7rem', '1.8rem', '2rem'],
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '1rem',
    textTransform: 'uppercase',
    fontFamily: 'heading',
    fontWeight: 'bold'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['2rem', '2.25rem', '2.5rem', '2.75rem', '3rem'],
    marginBottom: '1rem',
    border: 'none',
    fontFamily: 'subheading',
    fontWeight: '400',
    color: 'white',
    letterSpacing: '4px'
  },
  text: {
    lineHeight: '1.75',
    p: {
      lineHeight: '1.75',
      fontSize: 'inherit',
      textAlign: 'left'
    }
  },

  sideBySide1: {
    padding: ['1rem', '', '2rem', '3rem'],
    '.lazyload-wrapper': {
      minHeight: ['', '', '50vh'],
      maxHeight: ['', '', '80vh'],
      justifyContent: 'flex-start'
    },

    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      padding: ['2rem 1rem 2rem 3rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  contactUsBar: {
    backgroundColor: '#ada89d78',
    '.title': {
      marginBottom: '0rem'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    // height: '75vh',
    // marginTop: ['74px', '84px', '100px'],
    // height: ['calc(100vh - 74px)', 'calc(100vh - 84px)', 'calc(100vh - 100px)'],
    // '.slick-initialized .slick-slide > div, .slick-slider, .slick-list, .slick-track, .slick-slide > div, .slick-slide img': {
    //   height: [
    //     'calc(100vh - 74px)',
    //     'calc(100vh - 84px)',
    //     'calc(100vh - 100px)',
    //   ],
    // },
    '.logoHero': {
      maxWidth: '200px',
      margin: '0rem auto 1.5rem'
    },
    '.hero_content_container': {
      // backgroundColor: '#ffffff85',
      margin: '0rem',
      maxWidth: '600px',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      textAlign: 'center',
      backgroundColor: '#000000c2',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'solid 4px',
      borderColor: 'text',
      width: '90%'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      order: '1',
      color: 'white',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'center',
      order: '2',
      color: 'text'
      // display: 'none',
    },
    '.text': {
      variant: 'customVariants.text',

      order: '3',

      p: {
        color: 'white'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary',
      backgroundColor: 'black'
    },
    '::after': {
      content: "''",
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      bottom: '0px',
      background: 'linear-gradient(180deg,black,rgb(0 0 0 / 41%) 0%,rgb(0 0 0 / 0%) 50%,black 95%,black)'
    }
  },

  // homepageHero: {
  //   // height: '75vh',
  //   marginTop: ['74px', '84px', '100px'],
  //   height: ['calc(100vh - 74px)', 'calc(100vh - 84px)', 'calc(100vh - 100px)'],
  //   '.slick-initialized .slick-slide > div, .slick-slider, .slick-list, .slick-track, .slick-slide > div, .slick-slide img': {
  //     display: 'none',
  //     height: [
  //       'calc(100vh - 74px)',
  //       'calc(100vh - 84px)',
  //       'calc(100vh - 100px)',
  //     ],
  //   },
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   '.hero_content_container': {
  //     backgroundColor: '#ffffff85',
  //     height: '100%',
  //     width: '100%',
  //     display: 'flex',
  //     flexDirection: 'column',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     textAlign: 'center',
  //     maxWidth: 'unset',
  //     margin: '0rem',
  //   },
  //   '.title': {
  //     variant: 'customVariants.title',
  //     fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
  //     border: 'none',
  //     margin: '0rem 0rem 1rem',
  //     padding: '0rem',
  //     order: '2',
  //   },
  //   '.subtitle': {
  //     variant: 'customVariants.subtitle',

  //     textAlign: 'center',
  //     order: '1',
  //   },
  //   '.text': {
  //     variant: 'customVariants.text',
  //     maxWidth: '600px',
  //     margin: '0rem auto',
  //     order: '3',

  //     p: {},
  //   },
  //   a: {
  //     order: '4',
  //   },
  //   '.ctaButton': {
  //     variant: 'buttons.primary',
  //   },
  // },

  homepageHeroShout: {
    display: 'none'
  },

  homepageAbout: {
    background: 'none',
    padding: '5vh 1rem 0rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.section': {
      padding: '2rem'
    },
    '.title': {
      variant: 'customVariants.title',
      marginBottom: '1.5rem',
      textAlign: 'center',
      order: '1'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '1.5rem',
      textAlign: 'center',
      order: '2'
    },

    '.text': {
      variant: 'customVariants.text',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      order: '3',

      p: {
        letterSpacing: '1px',
        lineHeight: '1.7',
        textAlign: 'center'
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageBoxes: {
    '.box': {
      backgroundColor: 'text',
      color: 'white',
      margin: '1rem',
      textAlign: 'center',
      ':hover': {
        backgroundColor: 'primary'
      }
    }
  },

  homepageQuote1: {
    '.title': {
      variant: 'customVariants.title',
      marginBottom: '1.5rem',
      fontSize: ['2rem', '2.5rem', '3rem', '4rem', '5rem']
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '1.5rem'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'white',
      '> *': {
        color: 'white'
      }
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide1'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2'
  },

  homepageContact: {
    variant: 'customVariants.homepageEvents',
    padding: ['25vh 1rem', '25vh 1rem', '25vh 1rem', '25vh 1rem'],
    color: 'white',
    '.section': {
      backgroundColor: '#000000c2',
      border: 'solid 3px',
      borderColor: 'text',
      padding: '2rem 1rem'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white'
    },
    '.text': {
      variant: 'customVariants.text',
      p: {
        color: 'white',
        textAlign: 'center'
      }
    }
  },

  homepageShout: {
    '.text': {
      variant: 'customVariants.text',
      background: 'unset'
    },
    '.title': {
      color: 'text'
    },
    '.containerPopUp': {
      backgroundColor: 'black',
      maxWidth: '600px',
      '.shoutContentContainerPopUp': {
        color: 'white'
      },
      '.image': {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    variant: 'customVariants.sideBySide1',
    // backgroundColor: 'black',
    'div.content': {
      padding: ['1rem', '2rem', '1rem 3rem 1rem 1rem', '1rem 4rem 1rem 1rem'],
      // width: ['100%', '', '70%', '70%'],
      // width: ['100%', '100%', '100%', '100%'],
      '.subtitle': {
        // color: 'white',
      },
      // color: 'white',
      '.text': {
        '> *': {
          // color: 'white',
        }
      }
    },
    'div.lazyload-wrapper': {
      // width: ['100%', '', '35%', '35%'],
      // display: 'none',
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  // menu: {},
  servicesPage: {
    '#sec-z-i2jbTkT0qwyszOhAySNg': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      '> .menuContainer': {
        width: '100%'
      },
      '> .allInContainer': {
        width: ['100%', '', 'calc(50% - 2rem)'],
        margin: ['0rem', '', '1rem'],
        border: 'solid 1px lightgrey',

        flexGrow: '1',
        '.menuItemsContainer': {
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        },
        '.menuItemContainerImgActive': {
          width: '100%'
        },
        '.menuItemContentContainer': {},
        '.menuSectionTitle': {
          textAlign: 'left',
          padding: '1.5rem',
          borderBottom: '1px solid lightgrey'
        },
        '.menuItemName': {
          fontWeight: 'normal',
          fontSize: '1.25rem',
          margin: '0rem 0rem 0.5rem',
          '::before': {
            content: "'➤'",
            margin: '0.5rem'
          }
        },
        '.menuItemDescription': {
          opacity: '0.7'
        }
      }
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.albumName': {
      display: 'none'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },
    '.eventItemContainer': {
      padding: ['0.5rem', '1rem', '1.5rem', '2rem', '3rem'],
      flexDirection: ['column', 'column', 'row', 'row', 'row'],
      margin: '1.5rem 0rem 1.5rem',
      boxShadow: '2px 2px 10px lightgrey'
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: ['100%', '100%', '50%', '40%', '35%'],
      // height: '100%',
      maxHeight: 'unset',
      objectFit: 'contain',
      objectPosition: 'top',
      cursor: 'pointer',
      transition: 'all ease-in-out 0.8s',
      ':hover': {
        // opacity: '0.8',
        transform: 'scale(1.05)',
        filter: 'brightness(0.6)'
      }
      // border: 'solid 1px lightgrey',
    },
    '.eventItemContent': {
      width: ['100%', '100%', '50%', '60%', '65%'],
      padding: ['1rem', '1.5rem', '2rem', '3rem'],
      marginTop: ['1.5rem', '', '0rem'],
      paddingTop: ['1rem', '', '0rem'],
      borderTop: ['1px solid', '1px solid', 'none', ''],
      borderColor: ['secondary', 'secondary']
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemTitle': {
      lineHeight: ['1.5', '1.5', '1.5', '1.75', '1.75']
    },

    '.eventItemDescription': {
      maxHeight: 'unset',
      overflowY: 'scroll',
      fontSize: ['1rem', '', '', '1.1rem', '1.2rem'],
      lineHeight: ['1.5', '1.5', '1.5', '1.75', '1.75'],
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    },
    '.eventCTABtns': {
      marginTop: '1rem',
      a: {
        variant: 'buttons.primary',
        textDecoration: 'none'
      }
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'text'
    },
    backgroundPosition: 'center center',
    color: 'text',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'text',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'text',
      '::placeholder': {
        color: 'text'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    'h3.title': {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      color: 'white',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.1rem', '1.2rem', '1.3rem', '1.5rem', '1.6rem'],
      textAlign: 'center',
      marginBottom: '0rem',
      alignItems: 'center'
    },
    '.content_container': {
      padding: '1rem'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 2rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem 0rem', '1rem 2rem 0rem', '1rem 4rem 0rem', '2rem 5rem 0rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    },
    iframe: {
      filter: 'invert(0.85)'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'black'
    },
    '.imageContainer': {
      backgroundColor: '#8a7d74',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  },

  // ? ========================
  // ? =======  blog   ========
  // ? ========================
  blogMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.box': {
      backgroundColor: 'lightgrey'
    }
  },

  blogPage: {},

  // ? ========================
  // ? =====  services   ======
  // ? ========================

  menu: {
    '.menuSectionTitle': {
      backgroundColor: 'primary',
      padding: '1rem',
      margin: ['0rem 0rem 2rem', '', '0rem 1.5rem 1.5rem'],
      fontSize: ['1.5rem', '1.75rem', '2rem', '2rem', '2rem']
    },
    '.menuSectionDescription': {},
    '.menuItemContainer, .menuItemContainerImgActive': {},
    '.menuItemImageContainer': {},
    '.itemImageFill': {},
    '.menuItemContentContainer': {},
    '.menuItemName': {},

    '.menuItemDescription': {
      color: '#b5abab'
    }
  }
}
